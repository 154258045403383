<template>
  <div>
    <v-layout wrap justify-end>
      <v-flex xs12>
        <v-menu offset-y left>
          <template v-slot:activator="{ attrs, on }">
            <v-layout style="cursor:pointer" wrap justify-center v-bind="attrs" v-on="on">
              <v-flex xs12 text-center align-self-center>
                <v-icon color="#000" size="25">
                  mdi-account-circle-outline
                </v-icon>
              </v-flex>
              <v-flex
                xs12
                text-center
                align-self-center
                style="line-height: 0.5"
                pt-1
              >
                <span
                  style="
                    font-family: poppinsregular;
                    font-size: 12px;
                    color: #000;
                  "
                >
                  Account
                </span>
              </v-flex>
            </v-layout>
          </template>
          <v-card tile flat :elevation="0" max-width="250px">
            <v-layout wrap justify-center py-4>
              <v-flex pt-4 xs12 text-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 16px;
                    color: #000000;
                  "
                >
                  Welcome
                </span>
              </v-flex>
              <v-flex pt-2 xs12 text-center>
                <v-icon color="#CCB4B4" size="30">
                  mdi-account-circle-outline
                </v-icon>
              </v-flex>
              <v-flex xs12 text-center v-if="appUserrole === 'admin'">
                <router-link to="/Admin/profile">
                  <span
                    v-if="appLogin"
                    style="
                      font-family: poppinsmedium;
                      font-size: 13px;
                      color: #1e1b1b;
                      text-transform: uppercase;
                    "
                  >
                    
                    <span >
                      {{ appUser }}
                    </span><br>
                    <span>{{ appUserrole }}</span>
                  </span>
                </router-link>
              </v-flex>
              <v-flex xs12 text-center v-if="appUserrole !== 'admin'">
                <router-link to="/Officers/profile">
                  <span
                    v-if="appLogin"
                    style="
                      font-family: poppinsmedium;
                      font-size: 13px;
                      color: #1e1b1b;
                      text-transform: uppercase;
                    "
                  >
                    
                    <span >
                      {{ appUser }}
                    </span><br>
                    <span>{{ appUserrole }}</span>
                  </span>
                </router-link>
              </v-flex>
              <v-flex xs12 pt-2 text-center>
                <v-btn
                  v-if="!appLogin"
                  color="#0000001f"
                  outlined
                  tile
                  small
                  :ripple="false"
                  to="/login"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 14px;
                      
                    "
                     class="appColor"
                  >
                    LOGIN
                  </span>
                </v-btn>
                <v-btn
                  v-if="appLogin"
                  color="#0000001f"
                  outlined
                  tile
                  small
                  :ripple="false"
                  @click="appLogout"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 14px;
                      
                    "
                    class="appColor"
                  >
                    LOGOUT
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import Socket from "./../../Sockets/socket";
// import axios from 'axios'
export default {
  data() {
    return {
      user: {},
    };
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appUser() {
      return this.$store.state.userName;
    },
    appUserrole() {
      return this.$store.state.userRole;
    },
  },
  methods: {
    appLogout() {
      // Socket.authFunction(this);
      this.$store.commit("logoutUser", true);
    },
    // appLogout() {
			
		// 	axios({
		// 		method: "GET",
		// 		url: "/user/logout",
		// 		headers: {
		// 			"x-auth-token": localStorage.getItem("token")
		// 		}
		// 	}).then(response => {
		// 		if (response.data) {
		// 			localStorage.removeItem("token");
		// 			localStorage.removeItem("userRole");
		// 			if (window.location.pathname != '/') {
    //         this.$router.push('/login')

		// 			}
		// 		}
		// 	})
		// },
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
  },
};
</script>